// nutze Client Settings
@import "../global.scss";

.debug {
  overflow: auto;

  .admin {
    display: flex;
    overflow: auto;
  }
}

.viewsonic {
  padding: 20px;
  width: fit-content;

  div {
    padding: 10px;
    padding-left: 0;
  }

  button {
    margin-left: 10px;

    &.active {
      background-color: greenyellow;
    }
  }
}

.fanled {
  padding: 20px;

  .colors {
    display: flex;
  }

  h3 {
    width: 100%;
  }

  div {
    padding: 10px;
    padding-left: 0;
  }
}

.admin-mode {
  display: flex;
  flex-direction: column;
  align-items: center;

  .power {
    font-family: "Brix Sans Black", "Helvetica Neue", sans-serif;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    width: 150px;
    // padding-top: 50px;

    &.disabled {
      .button {
        pointer-events: none;
        opacity: 0.3;
      }
    }

    .button {
      border: 2px solid #fff;
      padding: 10px;
      min-width: 50px;

      &.on {
        background-color: var(--color-rehau-green);
      }

      &.off {
        background-color: var(--color-rehau-magenta);
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }
}
