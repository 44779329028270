// nutze Client Settings
@import "../global.scss";

.checkin {
  height: 798px;
  width: 1278px;
  border: 1px solid #fff;
  position: relative;
  overflow: hidden;

  user-select: none;

  &.local {
    border-color: black;
  }

  background-image: url("./../assets/images/Rehau_Keyvisual-checkin-bg.jpg");

  .title {
    position: relative;
    left: 5%;
    top: 5%;

    display: flex;
    justify-content: center;
    flex-direction: column;

    .l1 {
      font-family: "Brix Sans Bold", "Helvetica Neue", sans-serif;
      font-weight: bold;
      font-size: 40pt;
      line-height: 27pt;
    }
    .l2 {
      font-family: "Brix Sans Light", "Helvetica Neue", sans-serif;
      font-weight: 100;
      font-size: 60pt;
      color: var(--color-rehau-green);
    }
  }

  .admin {
    position: relative;
    left: calc(1280px / 2 - 250px);
    top: 100px;
    width: 500px;
    text-align: center;
    font-family: "Brix Sans Regular", "Helvetica Neue", sans-serif;
    font-size: 30pt;
    display: flex;
    flex-direction: column;
    align-items: center;

    .power {
      font-family: "Brix Sans Black", "Helvetica Neue", sans-serif;
      display: flex;
      justify-content: space-between;
      justify-items: center;
      align-items: center;
      width: 400px;
      padding-top: 50px;

      &.disabled {
        .button {
          pointer-events: none;
          opacity: 0.3;
        }
      }

      .button {
        border: 2px solid #fff;
        padding: 20px;
        min-width: 100px;

        &.on {
          background-color: var(--color-rehau-green);
        }

        &.off {
          background-color: var(--color-rehau-magenta);
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.3;
        }
      }
    }
  }

  .qrcode {
    width: 200px;
    height: 200px;
    // border: 2px solid #fff;
    position: relative;
    left: calc(1280px / 2 - 100px);
    top: 100px;
    padding: 0;
    img {
      height: 100%;
      width: auto;
    }
  }

  .welcome {
    position: relative;
    left: calc(1280px / 2 - 250px);
    top: 100px;
    width: 500px;
    text-align: center;
    font-family: "Brix Sans Regular", "Helvetica Neue", sans-serif;
    font-size: 30pt;
    white-space: pre;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .start {
      cursor: pointer;
      margin-top: 50px;
      font-family: "Brix Sans Bold", "Helvetica Neue", sans-serif;
      font-weight: bold;
    }
  }

  .info {
    position: relative;
    left: calc(1280px / 2 - 250px);
    top: 150px;
    width: 500px;
    font-family: "Brix Sans Regular", "Helvetica Neue", sans-serif;
    font-size: 30pt;
    text-align: center;
    white-space: pre;
  }

  .language {
    position: absolute;
    z-index: 1;
    bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;

    img {
      height: 80px;
      width: auto;
    }
  }

  .audio {
    position: absolute;
    z-index: 2;
    bottom: 30px;
    left: 70px;
    width: 100px;
    display: flex;
    justify-content: left;
    cursor: pointer;

    img {
      height: 80px;
      width: auto;
    }
  }
}
