.canvas {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    &.screen {
        top: 80px;
        width: 1810px;
        left: 49px;
        overflow: hidden;
    }

    &.outside {
        top: -35px;
        left: -90px;
        width: 2020px;
    }

    &.debug {
        top: 350px;
        left: 400px;
        width: 600px;
    }

    &.mobile {
        width: 1500px;
        left: 100px;
        top: 30px;
    }
}