/* GLOBAL */

:root {
  /* Colors */
  --color-black: rgb(0, 0, 0);
  --color-white: rgb(255, 255, 255);
  --color-rehau-green: #56A28D;
  --color-rehau-magenta: #CB2C61;

  @media screen and (max-width: 768px), (max-height: 512px) {
  }
}


/* ALLGEMEIN */

body {
  margin: 0;
  padding: 0;
  font-family: "BrixSans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-black);
  color: var(--color-white);
  overflow: hidden;
}

h2 {
  padding: 0;
  margin: 0;
  color: var(--color-white);
  font-size: 10pt;
}


/* FONTS */

@font-face {
  font-family: "BrixSans";
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/BrixSansLight.otf") format("opentype");
}
@font-face {
  font-family: "BrixSans";
  font-style: normal;
  font-weight: 200;
  src: url("./assets/fonts/BrixSansRegular.otf") format("opentype");
}
@font-face {
  font-family: "BrixSans";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/BrixSansBlack.otf") format("opentype");
}

/* FONTS FLASH */

@font-face {
  font-family: "Brix Sans Light";
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/BrixSansLight.otf") format("opentype");
}

@font-face {
  font-family: "Brix Sans Regular";
  font-style: normal;
  font-weight: 200;
  src: url("./assets/fonts/BrixSansRegular.otf") format("opentype");
}

@font-face {
  font-family: "Brix Sans Black";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/BrixSansBlack.otf") format("opentype");
}