.led {
    padding: 20px;
    width: fit-content;
    height: 80vh;
    overflow-y: auto;

    div {
        padding: 10px;
        padding-left: 0;
    }

    .playlist {
        button {
            margin-left: 10px;

            &.active {
                background-color: greenyellow;
            }
        }
    }
    .status {
        color: white;
    }
}
